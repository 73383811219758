import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

const Title = styled(Form.Label)`
    font-size: larger;
    font-weight: bold;
    text-align: center;
`;

const Subtitle = styled(Form.Label)`
    font-weight: bold;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
`;

const SearchForm = styled(Form)`
    background-color: #fafafa;
    padding: 8px;
    border-radius: 15px;
`;

const SearchButton = styled(Button)`
    width: 5em;
    margin-top: 10px;
`;

function SearchFormComponent(props) {
    const handlePrice = (e) => {
        e.target.value = parseInt(e.target.value) || "";
        props.setSearchContidion({ ...props.searchCondition, [e.target.name]: e.target.value });
    };

    const handleCondition = (e) => {
        if (e.target.checked) {
            props.searchCondition[e.target.name].push(e.target.value);
        } else {
            props.searchCondition[e.target.name].splice(
                props.searchCondition[e.target.name].indexOf(e.target.value),
                1
            );
        }
        props.setSearchContidion({ ...props.searchCondition, [e.target.name]: props.searchCondition[e.target.name] });
    };

    const handleSubmit = () => {
        props.searchItem();
    };

    return (
        <SearchForm>
            <Form.Group as={Row}>
                <Title>絞り込み</Title>
            </Form.Group>
            <Form.Group as={Row}>
                <Subtitle>値段</Subtitle>
                <Col md="auto">
                    <Form.Control type="text" name="minPrice" placeholder="Min" htmlSize="3" onChange={handlePrice} />
                </Col>
                <Col md="auto">-</Col>
                <Col md="auto">
                    <Form.Control type="text" name="maxPrice" placeholder="Max" htmlSize="3" onChange={handlePrice} />
                </Col>
            </Form.Group>
            <Subtitle>商品の状態</Subtitle>
            <Form.Group as={Row}>
                <Col>
                    <Form.Check
                        type="checkbox"
                        name="itemCondition"
                        value="ic00"
                        label="新品・未使用"
                        onChange={handleCondition}
                        aria-label="新品・未使用"
                    />
                    <Form.Check
                        type="checkbox"
                        name="itemCondition"
                        value="ic01"
                        label="未使用に近い"
                        onChange={handleCondition}
                        aria-label="未使用に近い"
                    />
                    <Form.Check
                        type="checkbox"
                        name="itemCondition"
                        value="ic02"
                        label="目立った傷や汚れなし"
                        onChange={handleCondition}
                        aria-label="目立った傷や汚れなし"
                    />
                    <Form.Check
                        type="checkbox"
                        name="itemCondition"
                        value="ic03"
                        label="やや傷や汚れあり"
                        onChange={handleCondition}
                        aria-label="やや傷や汚れあり"
                    />
                    <Form.Check
                        type="checkbox"
                        name="itemCondition"
                        value="ic04"
                        label="傷や汚れあり"
                        onChange={handleCondition}
                        aria-label="傷や汚れあり"
                    />
                    <Form.Check
                        type="checkbox"
                        name="itemCondition"
                        value="ic05"
                        label="全体的に状態が悪い"
                        onChange={handleCondition}
                        aria-label="全体的に状態が悪い"
                    />
                </Col>
            </Form.Group>
            <Subtitle>配送料の負担</Subtitle>
            <Form.Group as={Row}>
                <Col>
                    <Form.Check
                        type="checkbox"
                        name="shippingPayer"
                        value="sp00"
                        label="出品者負担"
                        onChange={handleCondition}
                        aria-label="出品者負担"
                    />
                    <Form.Check
                        type="checkbox"
                        name="shippingPayer"
                        value="sp01"
                        label="購入者負担"
                        onChange={handleCondition}
                        aria-label="購入者負担"
                    />
                </Col>
            </Form.Group>
            <Subtitle>販売オプション</Subtitle>
            <Form.Group as={Row}>
                <Col>
                    <Form.Check
                        type="checkbox"
                        name="shippingMethod"
                        value="sm00"
                        label="匿名配送"
                        onChange={handleCondition}
                        aria-label="匿名配送"
                    />
                    <Form.Check
                        type="checkbox"
                        name="shippingMethod"
                        value="sm01"
                        label="郵便局/コンビニ受取"
                        onChange={handleCondition}
                        aria-label="郵便局/コンビニ受取"
                    />
                </Col>
            </Form.Group>
            <Subtitle>販売状況</Subtitle>
            <Form.Group as={Row}>
                <Col>
                    <Form.Check
                        type="checkbox"
                        name="itemStatus"
                        value="is00"
                        label="販売中"
                        onChange={handleCondition}
                        aria-label="販売中"
                        defaultChecked
                    />
                    <Form.Check
                        type="checkbox"
                        name="itemStatus"
                        value="is01"
                        label="売り切れ"
                        onChange={handleCondition}
                        aria-label="売り切れ"
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md="auto">
                    <SearchButton onClick={handleSubmit}>検索</SearchButton>
                </Col>
            </Form.Group>
        </SearchForm>
    );
}

export default SearchFormComponent;
