import React, { useState, useEffect, useRef } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import axios from "axios";
import qs from "qs";
import SearchFormComponent from "./SearchFormComponent";
import ItemList from "./ItemList";
import styled from "styled-components";

const Summay = styled.summary`
    text-align: center;
    background-color: #afafaf;
`;

function App() {
    const [searchCondition, setSearchContidion] = useState({
        itemName: "",
        minPrice: "",
        maxPrice: "",
        itemCondition: [],
        shippingPayer: [],
        shippingMethod: [],
        itemStatus: ["is00"],
    });

    const inputEl = useRef(null);

    const [searchResult, setSearchResult] = useState([]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        inputEl.current.focus();
    }, []);

    const handleItemName = (e) => {
        setSearchContidion({ ...searchCondition, itemName: e.target.value });
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            searchItem();
            e.preventDefault();
        }
    };

    const searchItem = () => {
        if (searchCondition.itemName.trim() === "") {
            return;
        }
        setSearchResult([]);
        setProgress(1);
        const id = setInterval(() => {
            setProgress((progress) => {
                if (progress < 100) {
                    return progress + 2;
                } else {
                    clearInterval(id);
                    return 0;
                }
            });
        }, 150);

        const params = qs.stringify(searchCondition, { arrayFormat: "comma" });
        axios
            .get(process.env.REACT_APP_API_URL + "?" + params)
            .then((response) => {
                setSearchResult(response.data.results);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setProgress(() => {
                    clearInterval(id);
                    return 0;
                });
            });
    };

    return (
        <Container>
            <Modal show={progress > 0}>
                <Modal.Header>横断検索中</Modal.Header>
                <Modal.Body>
                    <ProgressBar now={progress} label={`${progress}%`} />
                </Modal.Body>
            </Modal>
            <Container style={{ padding: 10 }}>
                <Row>
                    <Col md="auto">
                        <a href="https://fritoku.com/" aria-label="フリトク">
                            <svg
                                width="120"
                                height="40"
                                viewBox="0 0 197.004 45.246"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <defs>
                                    <path id="a" d="M117.156 201.618H979.48v290.166H117.156z" />
                                </defs>
                                <g transform="matrix(.26458 0 0 .26458 -34.024 -61.282)">
                                    <path d="M245.594 323.867v-31.125q0-2.625-2.25-4.5-2.063-2.062-5.438-2.062H135.531q-3-.188-5.062-2.063-1.875-2.062-1.875-5.062V239.68q0-2.813 1.875-4.688 2.062-2.062 5.062-2.062H266.97q16.312.375 23.062 7.5 6.938 7.125 7.125 24.187v75q.563 33.375-15.187 48.375t-47.813 14.625h-79.875q-3.375 0-5.625-2.062-2.062-1.875-2.062-4.875v-39.188q0-3 2.062-4.875 2.25-2.062 5.625-2.062h65.438q14.25.187 20.062-5.063 5.813-5.25 5.813-20.625zM423.531 323.867V240.43q.188-3 2.063-4.875 2.062-2.063 4.875-2.063h37.125q3.375 0 5.437 2.063 2.063 1.875 2.063 4.875v99.187q.562 33.375-15.188 48.375t-47.812 14.625h-69.75q-3.375 0-5.625-2.062-2.063-1.875-2.063-4.875v-39.188q0-3 2.063-4.875 2.25-2.062 5.625-2.062h55.312q14.25.187 20.063-5.063 5.812-5.25 5.812-20.625zm-37.5-83.062v84.937q-.187 2.813-2.25 4.875-1.875 1.875-4.687 2.063H341.78q-2.812-.188-4.875-2.063-2.062-2.062-2.062-4.875v-84.937q0-3 2.062-5.063 2.063-2.25 4.875-2.437h37.313q2.812.187 4.687 2.437 2.063 2.063 2.25 5.063zM592.469 275.867l81 48q3.562 1.688 4.875 4.5 1.312 2.625-1.125 7.5l-18 31.125q-2.25 4.875-5.813 6.375-3.562 1.5-8.062-1.125l-52.875-31.875v55.313q-.188 2.812-2.25 4.687-1.875 2.063-4.688 2.25h-39.937q-2.813-.187-4.875-2.25-2.063-1.875-2.063-4.687V239.305q0-3 2.063-5.063 2.062-2.25 4.875-2.437h39.937q2.813.187 4.688 2.437 2.062 2.063 2.25 5.063zM711.719 330.805q-3 0-4.875-2.063-1.875-2.25-2.063-5.625V289.18q.188-3 2.063-5.063 1.875-2.25 4.875-2.437h12.187q3.188 0 5.25-2.063 2.063-2.062 2.063-5.062v-36q.187-3 2.062-4.875 2.063-2.063 4.875-2.063h127.5q3.375 0 5.438 2.063 2.062 1.875 2.062 4.875v101.062q.563 33.375-15.187 48.375t-47.813 14.625h-97.5q-3.375 0-5.625-2.062-2.062-1.875-2.062-4.875v-39.188q0-3 2.062-4.875 2.25-2.062 5.625-2.062h79.313q14.25.187 21.937-6.938 7.688-7.125 7.688-22.5v-16.125q0-14.062-7.688-21.562-7.687-7.688-21.937-7.5-14.813-.188-22.313 7.5-7.5 7.687-7.5 21.562v19.125q0 3.375-2.062 5.625-2.063 2.063-5.438 2.063z" />
                                </g>
                            </svg>
                        </a>
                    </Col>
                    <Col md={6}>
                        <Form>
                            <InputGroup>
                                <Form.Control
                                    ref={inputEl}
                                    type="text"
                                    name="query"
                                    placeholder="何をお探しですか？"
                                    onChange={handleItemName}
                                    onKeyPress={handleKeyPress}
                                />
                                <Button variant="outline-dark" onClick={searchItem} aria-label="検索">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </Button>
                            </InputGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col md="auto">
                        <div className="d-md-none">
                            <details>
                                <Summay>詳細検索</Summay>
                                <SearchFormComponent {...{ searchCondition, setSearchContidion, searchItem }} />
                            </details>
                        </div>
                        <div className="d-none d-md-block">
                            <SearchFormComponent {...{ searchCondition, setSearchContidion, searchItem }} />
                            <hr />
                            <Card style={{ width: 280 }}>
                                <a href="https://amatan.net" target="_blank" rel="noopener noreferrer">
                                    <Card.Img
                                        width="278"
                                        height="145"
                                        src="https://amatan.net/wpside_400x210.webp"
                                        alt="アマ探"
                                    />
                                </a>
                                <Card.Body>
                                    <Card.Text>
                                        Amazonタイムセール検索に特化したサイトです。数量限定タイムセール品を簡単に探せます。
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <hr />
                            <Card style={{ width: 280 }}>
                                <a href="https://byoudene.com" target="_blank" rel="noopener noreferrer">
                                    <Card.Img
                                        width="278"
                                        height="145"
                                        src="https://byoudene.com/wpside_400x210.webp"
                                        alt="秒で値"
                                    />
                                </a>
                                <Card.Body>
                                    <Card.Text>
                                        1円単位の価格比較に疲れた方へ。ざっくり値段がわかればいいんだよ！の方へ。
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <ItemList {...{ searchCondition, searchResult }} />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default App;
