import React from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import styled from "styled-components";

const SearchResultPrice = styled.div`
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.4);
    text-align: right;
    font-weight: bold;
    font-size: 1em;
    width: 100%;
    position: absolute;
    bottom: 0px;
`;

const SearchResultSoldout = styled.div`
    padding: 2px;
    color: #ffffff;
    background-color: rgba(255, 0, 0, 0.8);
    font-weight: bold;
    position: absolute;
`;

const formatter = new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
});

function ItemList(props) {
    const { searchResult } = props;

    return (
        <CardGroup style={{ display: "flex", flexFlow: "row wrap", margin: 0, justifyContent: "space-around" }}>
            {searchResult.map((item, index) => (
                <Card key={index} style={{ minWidth: 154, flex: "0 0 0" }}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <OverlayTrigger
                            placement={"auto-start"}
                            overlay={<Tooltip id={`tooltip-${item.url}`}>{item.name}</Tooltip>}
                        >
                            <Card.Img
                                src={item.imgUrl}
                                style={{ width: 154, height: 150, objectFit: "cover" }}
                                alt="Card image"
                            />
                        </OverlayTrigger>
                    </a>
                    <SearchResultPrice>{formatter.format(item.price)}</SearchResultPrice>
                    {item.soldout === 1 && <SearchResultSoldout>SoldOut</SearchResultSoldout>}
                </Card>
            ))}
        </CardGroup>
    );
}
export default ItemList;
